<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="List Tarif Retribusi" class="panel panel-success">
            <!-- Tools -->
            <div class="pl-4 py-2 border shadow-sm">
                <span class="px-2"
                    ><b-button variant="primary" @click="create"
                        ><i class="fa fa-plus pr-1"></i> Tambah Tarif Retribusi</b-button
                    ></span
                >
                <span
                    ><b-button variant="primary" @click="reload"
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <!-- end of Tools -->

            <!-- Table -->
            <!-- Filter -->
            <b-row>
                <b-col md="6" class="mb-3">
                    <b-form-group
                        :label-cols="3"
                        label="Filter By"
                        class="mb-0 my-1"
                    >
                        <b-input-group size="md">
                            <b-form-select
                                v-model="filterBy"
                                :options="fieldOptions"
                                @change="changeFilterBy()"
                            >
                                <option slot="first" :value="'all'"
                                    >All Data</option
                                >
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group
                        :label-cols="3"
                        label="Filter"
                        class="mb-0"
                        description="Type to Search or Click Clear to Stop Searching "
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                @keyup.enter="testFunc(filter)"
                                placeholder="Type to Search"
                                debounce="500"
                            />
                            <b-input-group-append>
                                <b-btn
                                    :disabled="!filter"
                                    @click="filter = ''"
                                    variant="info"
                                    >Clear</b-btn
                                >
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">
                    <b-form-group
                        :label-cols="3"
                        label="Sort"
                        class="mb-0 my-1"
                    >
                        <b-input-group size="md">
                            <b-form-select
                                v-model="sortBy"
                                :options="fieldOptions"
                            >
                                <option slot="first" :value="null"
                                    >-- none --</option
                                >
                            </b-form-select>
                            <b-form-select
                                :disabled="!sortBy"
                                v-model="sortDesc"
                                slot="append"
                            >
                                <option :value="false">Asc</option>
                                <option :value="true">Desc</option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- end filter section -->
            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive
                bordered
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'actions' ? '105px' : '',
                        }"
                    />
                </template>

                <template #head()="data">
                    <span style="white-space: pre;">{{ data.label }}</span>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="edit"
                            ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="swalNotification('error', data.item.id)"
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
        </panel>

        <!-- Modal Add Target -->
        <b-modal
            :title="
                editMode
                    ? 'Edit Data Tarif Retribusi'
                    : 'Tambah Data Tarif Retribusi'
            "
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <form
                @submit.prevent="editMode ? updated(spt_kabkota_id) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <b-alert variant="danger" show v-if="message">{{
                    message
                }}</b-alert>
                <!-- input form -->
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Dasar Hukum <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="DH003"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kode sub 2 Jenis Reribusi <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="skpdOption"
                                    :reduce="(nama) => nama.id"
                                    placeholder="Pilih Kode Sub 2 Jenis Retribusi"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Parameter 1 <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="-"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Parameter 2</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="-"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Parameter 3</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="-"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Parameter 4</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="-"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Tarif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan Tarif Biaya"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Tarif Tampilkan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12 py-2">
                                <div class="radio radio-css radio-inline">
                                    <input
                                        type="radio"
                                        id="inlineCssRadio1"
                                        value="1"
                                    />
                                    <label for="inlineCssRadio1">YA</label>
                                </div>
                                <div class="radio radio-css radio-inline">
                                    <input
                                        type="radio"
                                        id="inlineCssRadio2"
                                        value="0"
                                    />
                                    <label for="inlineCssRadio2">Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" squared>
                        <i class="fa fa-save"></i> Simpan
                    </b-button>
                </span>
                <span class="float-right">
                    <b-button
                        variant="outline-secondary"
                        squared
                        @click="close"
                    >
                        <i class="fa fa-times"></i> Tutup
                    </b-button>
                </span>
                <!-- end of input form-->
            </form>
        </b-modal>
        <!-- end of Modal -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";

export default {
    components: { HRetribusi },
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "nama",
                    label: "DASAR HUKUM",
                    sortable: true,
                },
                {
                    key: "npwr",
                    label: "KODE REKENING",
                    sortable: true,
                },
                {
                    key: "nik",
                    label: "JENIS RETRIBUSI",
                    sortable: true,
                },
                {
                    key: "pangkat2",
                    label: "JENIS SUB2",
                },
                {
                    key: "pangkat3",
                    label: "PARAMETER",
                },
                {
                    key: "pangkat5",
                    label: "TARIF",
                },
                {
                    key: "pangkat",
                    label: "JENIS SUB",
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
            showModalForm: false,
            skpdOption: [],
            editMode: false,
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/manajemen/tarif", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({name: 'Login'})
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/manajemen/tarif/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800,
                            });
                            this.reload();
                        }).catch(error => {
                            if (error.response.status === 401) {
                                localStorage.removeItem("ER_token");
                                this.$router.push({name: 'Login'})
                            }
                        });
                }
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditRefRek1", params: { id: index } });
        },
        // show modal
        create() {
            // this.resetForm();
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            this.editMode = false;
        },
        // tombol reset form
        // resetForm() {
        //     this.form.reset();
        //     this.form.clear();
        // },
        // edit
        edit() {
            // this.resetForm()
            this.editMode = true;
            // this.spt_kabkota_id = id
            // this.getSptKabkota(id)
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // tutup modal
        close() {
            this.$root.$emit("bv::hide::modal", "modal");
            this.editMode = false;
        },
    },
};
</script>
